import React, {SVGProps} from "react";


const SvgCustomRo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512 512'
        xmlSpace='preserve'
        width='1em'
        height='1em'
        className='svg-icon' {...props}>
        <path
            style={{fill: '#FF4B55'}}
            d="M400,0h-58.667v512H400c61.856,0,112-50.144,112-112V112C512,50.144,461.856,0,400,0z"/>
        <rect
            x="170.67"
            style={{fill: '#FFE15A'}}
            width="170.67"
            height="512"/>
        <path
            style={{fill: '#41479B'}}
            d="M112,0C50.144,0,0,50.144,0,112v288c0,61.856,50.144,112,112,112h58.667V0H112z"/>
    </svg>
)

export default SvgCustomRo;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InvitationState {
    isAdded: boolean,
    isUpdated: boolean
}

const initialState: InvitationState = {
    isAdded: false,
    isUpdated: false,
}

export const InvitationSlice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {
        addInvitation: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateInvitation: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addInvitation, updateInvitation} = InvitationSlice.actions;
export default InvitationSlice.reducer;

import React, {FC, ReactNode, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import Button, {IButtonProps} from '../../../components/bootstrap/Button';
import {HeaderRight} from '../../../layout/Header/Header';
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle,} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import LANG, {getLangWithKey, ILang} from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import SendEmailModal from "../../../components/dashboard/general-functionalities/SendEmailModal";

interface ICommonHeaderRightProps {
    beforeChildren?: ReactNode;
    afterChildren?: ReactNode;
}

const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({beforeChildren, afterChildren}) => {
    const [isOpenSendEmail, setIsOpenSendEmail] = useState<boolean>(false);
    const {darkModeStatus, setDarkModeStatus} = useDarkMode();
    const styledBtn: IButtonProps = {
        color: darkModeStatus ? 'dark' : 'light',
        hoverShadow: 'default',
        isLight: !darkModeStatus,
        size: 'lg',
    };

    const {i18n} = useTranslation();
    const changeLanguage = (lng: ILang['key']['lng']) => {
        i18n.changeLanguage(lng);
        showNotification(
            <span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1'/>
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
            'You updated the language of the site.',
        );
    };

    /**
     * Language attribute
     */
    useLayoutEffect(() => {
        document.documentElement.setAttribute('lang', i18n.language);
    });


    return (
        <HeaderRight>
            <div className='row g-3 mt-0'>
                {beforeChildren}

                {/* Dark Mode */}
                <div className='col-auto'>
                    <Popovers trigger='hover' desc='Dark / Light mode'>
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...styledBtn}
                            size={'sm'}
                            onClick={() => setDarkModeStatus(!darkModeStatus)}
                            className='btn-only-icon'
                            data-tour='dark-mode'>
                            <Icon
                                icon={darkModeStatus ? 'LightMode' : 'DarkMode'}
                                color={darkModeStatus ? 'light' : 'primary'}
                                className='btn-icon'
                            />
                        </Button>
                    </Popovers>
                </div>

                {/* Quick Panel */}
                <div className='col-auto'>
                    <Dropdown>
                        <DropdownToggle hasIcon={false}>
                            <Button size={'sm'} color={darkModeStatus ? 'dark' : 'light'} icon='fa-solid fa-qrcode' aria-label='Quick menu'>
                                Quick actions
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
                            <div className='row g-0'>
                                <div
                                    className={classNames(
                                        'col-12',
                                        'p-4',
                                        'd-flex justify-content-center',
                                        'fw-bold fs-6',
                                        {
                                            'text-info': !darkModeStatus,
                                            'text-light': darkModeStatus,
                                        },
                                        {
                                            'bg-l25-light': !darkModeStatus,
                                            'bg-lo25-light': darkModeStatus,
                                        },
                                    )} style={{borderBottom: '2px dashed #F0F0F0'}}>
                                    Quick Actions
                                </div>
                                <div
                                    className={classNames(
                                        'col-6 p-4 transition-base cursor-pointer bg-light-hover',
                                        'border-end border-bottom',
                                        {'border-dark': darkModeStatus},
                                    )} onClick={() => setIsOpenSendEmail(true)}>
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                        <img alt={'reminder'} src={'./img/reminder.svg'} width={30}/>
                                        <span className={'fw-bold'} style={{fontSize: 12}}>Reminders</span>
                                        <small className='text-muted'>Send email</small>
                                    </div>
                                </div>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                    <SendEmailModal isOpen={isOpenSendEmail}
                                    setIsOpen={setIsOpenSendEmail} />
                </div>
                {afterChildren}
            </div>
        </HeaderRight>
    );
};
CommonHeaderRight.propTypes = {
    beforeChildren: PropTypes.node,
    afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
    beforeChildren: null,
    afterChildren: null,
};

export default CommonHeaderRight;

import React, {useEffect, useState} from "react";
import Modal, {ModalBody, ModalFooter, ModalHeader} from "../../bootstrap/Modal";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Select from "react-select";
import selectStyles from "../../../styles/react-select/selectStyles";
import Button from "../../bootstrap/Button";
import Spinner from "../../bootstrap/Spinner";
import {useFormik} from "formik";
import jwtInstance from "../../../guards/jwt.interceptor";
import {API_COMPANY, API_EMAIL_NOTIFICATION} from "../../../utils/restApi";
import {errorResponse, successResponse} from "../../../utils/getResponse";
import Checks from "../../bootstrap/forms/Checks";
import Textarea from "../../bootstrap/forms/Textarea";
import Input from "../../bootstrap/forms/Input";

const SendEmailModal = ({isOpen, setIsOpen}: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any>([]);

    useEffect(() => {
        jwtInstance.get(API_COMPANY.all)
            .then(response => {
                if (response.status === 200) {
                    setCompanies(response.data);
                }
            }).catch(error => {
                errorResponse(error, 'Eroare', 'danger');
        })
    }, []);
    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            allCompanies: false,
            subject: '',
            companiesUuid: [],
            messageRo: '',
            messageEn: '',
        },
        validate: (values) => {
            const errors: {
                companiesUuid?: string,
                subject?: string,
                messageRo?: string,
                messageEn?: string,
            } = {};

            if (values.companiesUuid.length === 0 && !values.allCompanies)  {
                errors.companiesUuid = 'Required';
            }
            if (!values.subject) {
                errors.subject = 'Required';
            }
            if (!values.messageRo) {
                errors.messageRo = 'Required';
            }
            if (!values.messageEn) {
                errors.messageEn = 'Required';
            }

            return errors;
        },
        onSubmit: (values) => {
            setIsLoading(true);
            jwtInstance.post(API_EMAIL_NOTIFICATION.sendBulkNotification, values)
                .then(response => {
                    if (response.status === 200) {
                        successResponse(response, 'Send email', 'success');
                        formik.resetForm();
                        setIsOpen(false);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    errorResponse(error, 'Error', 'danger');
            })
        }
    });
    return(
        <Modal
            id={"SendEmailModal"}
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false);
                formik.resetForm();
            }}
            isStaticBackdrop
            isScrollable={false}
            size={'lg'}
        >
            <ModalHeader className={"mx-2"} setIsOpen={() => setIsOpen(false)}>
                <div className={'d-flex align-items-center'}>
                    <img loading={'lazy'} src={'/img/invoicing-2.png'} alt={'logo'} width={40}/>
                    <div className={'fs-5'} style={{marginLeft: 5}}>
                        <span
                            style={{fontWeight: 600}}>{`Email service`}</span>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <span className={'fw-bold'}>{!isLoading && 'Do you want to send notification to the customers?'}</span>
                {isLoading ? (
                    <>
                        <span className={'fw-bold text-muted'}>{'Wait, notification is sent to all customers, do not close the window...'}</span>
                        <div className={'d-flex justify-content-center'}>
                            <img width={150} src={'./img/send_email_notification.gif'} alt={'email'} />
                        </div>
                    </>
                ) : (
                    <form className={'row g-4 mt-2'} onSubmit={formik.handleSubmit}>
                        <div className={'col-md-6'}>
                            <Checks
                                type='switch'
                                id='allCompanies'
                                label={<span style={{fontWeight: 600}}>{'All companies'}</span>}
                                name='allCompanies'
                                onChange={() => formik.setFieldValue('allCompanies', !formik.values.allCompanies)}
                                checked={formik.values.allCompanies}/>
                        </div>

                        {!formik.values.allCompanies && (
                            <div className={'col-md-12'}>
                                <FormGroup id={'companiesUuid'} label={'Select company/companies'}>
                                    <Select
                                        isMulti
                                        id={"companiesUuid"}
                                        placeholder={"Select company/companies..."}
                                        name={"companiesUuid"}
                                        isSearchable
                                        styles={selectStyles}
                                        onChange={(newValue) => {
                                            formik.setFieldValue('companiesUuid', newValue.map((obj: any) => obj?.value));
                                        }}
                                        options={companies.map((companyObj: any) => {
                                            return {
                                                value: companyObj?.uuid,
                                                label: `${companyObj?.companyName}`
                                            }
                                        })}
                                    />
                                </FormGroup>
                                {formik.errors.companiesUuid && formik.touched.companiesUuid ? (
                                    <div style={{fontSize: "12px"}} className={"text-danger"}>
                                        {formik.errors.companiesUuid}
                                    </div>
                                ) : ""}
                            </div>
                        )}
                        <div className='col-md-12'>
                            <FormGroup
                                id='subject'
                                label='Email subject'>
                                <Input
                                    type='text'
                                    placeholder={'Subject..'}
                                    value={formik.values.subject}
                                    isTouched={formik.touched.subject}
                                    invalidFeedback={formik.errors.subject}
                                    isValid={formik.isValid}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                            </FormGroup>
                        </div>
                        <div className={'col-md-12'}>
                            <FormGroup id={'messageRo'} label={'Message RO'}>
                                <Textarea placeholder={'Type message...'}
                                          rows={10}
                                          value={formik.values.messageRo}
                                          isTouched={formik.touched.messageRo}
                                          invalidFeedback={formik.errors.messageRo}
                                          isValid={formik.isValid}
                                          validFeedback={'Looks good'}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                />
                            </FormGroup>
                        </div>
                        <div className={'col-md-12'}>
                            <FormGroup id={'messageEn'} label={'Message EN'}>
                                <Textarea placeholder={'Type message...'}
                                          rows={10}
                                          value={formik.values.messageEn}
                                          isTouched={formik.touched.messageEn}
                                          invalidFeedback={formik.errors.messageEn}
                                          isValid={formik.isValid}
                                          validFeedback={'Looks good'}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                />
                            </FormGroup>
                        </div>

                        <ModalFooter>
                            <Button
                                type={'submit'}
                                isDisable={isLoading}
                                size={"sm"}
                                color={"info"}
                                icon={"fa-regular fa-paper-plane"}
                            >
                                {isLoading && (
                                    <Spinner isSmall inButton isGrow/>
                                )}
                                {isLoading ? "Sending..." : "Send"}
                            </Button>
                            <Button
                                size={"sm"}
                                color={"danger"}
                                icon={"fa-solid fa-xmark"}
                                onClick={() => {
                                    setIsOpen(false);
                                    formik.resetForm();
                                }}
                            >
                                {"Cancel"}
                            </Button>
                        </ModalFooter>
                    </form>
                )}
            </ModalBody>
        </Modal>
    )
};

export default SendEmailModal;

import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const LightLogo: FC<ILogoProps> = ({ width, height }) => {

	// width={height !== 854 && !!height ? height * (2155 / 854) : width}
	// height={width !== 2155 && !!width ? width * (854 / 2155) : height}
	return (
		<img alt={'logo'} width={height !== 854 && !!height ? height * (2155 / 854) : width}
			 height={width !== 2155 && !!width ? width * (854 / 2155) : height}
		src={'/logo.svg'}/>
	);
};
LightLogo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
LightLogo.defaultProps = {
	width: 2155,
	height: 854,
};

export default LightLogo;

import React from 'react';
import {dashboardMenu, demoPages} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers = [
    {path: demoPages.login.path, element: null, exact: true},
    {path: demoPages.resetPassword.path, element: null, exact: true},
    {path: demoPages.verifyAccount.path, element: null, exact: true},
    {path: demoPages.requestRental.path, element: null, exact: true},
    {path: demoPages.invoice.path, element: null, exact: true},
    {path: demoPages.page404.path, element: null, exact: true},
    {path: dashboardMenu.dashboard.path, element: <DashboardHeader/>, exact: true},
    {
        path: `*`,
        element: <DefaultHeader/>,
    },
];

export default headers;

import React, {ReactNode, useContext, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, {NavigationLine} from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {administrationPages, appConfigs, dashboardMenu, generalPages} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, {AsideBody, AsideFoot, AsideHead} from '../../../layout/Aside/Aside';

const DefaultAside = () => {

    const user = JSON.parse(`${localStorage.getItem('user')}`);
    const {asideStatus, setAsideStatus} = useContext(ThemeContext);

    const [doc, setDoc] = useState(false);

    const {t} = useTranslation(['translation', 'menu']);

    return (
        <>
            {user?.token && (
                <Aside>
                    <AsideHead>
                        <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus}/>
                    </AsideHead>
                    <AsideBody>
                        <Navigation menu={dashboardMenu} id='aside-dashboard'/>
                        <NavigationLine/>
                        {!doc && (
                            <>
                                <Navigation id={'aside-administration-pages'} menu={administrationPages}/>
                                <NavigationLine/>
                                <Navigation menu={generalPages} id='aside-general-pages'/>
                                <NavigationLine/>
                                {user?.roles[0] === 'ROLE_SUPER_ADMIN' && (
                                    <>
                                        <Navigation menu={appConfigs} id='app-configs'/>
                                        <NavigationLine/>
                                    </>
                                )}
                                {/*<NavigationLine />*/}
                            </>
                        )}
                    </AsideBody>
                    <AsideFoot>
                        <User/>
                    </AsideFoot>
                </Aside>
            )}
        </>
    );
};

export default DefaultAside;

import React, {useEffect, useState} from "react";
import jwtInstance from "../../../guards/jwt.interceptor";
import {API_EXCHANGE} from "../../../utils/restApi";
import {errorResponse} from "../../../utils/getResponse";
import {SubheaderSeparator} from "../../../layout/SubHeader/SubHeader";
import useDarkMode from "../../../hooks/useDarkMode";


const ExchangeRate = () => {

    const [exchangeRate, setExchangeRate] = useState<any>(null);
    const {darkModeStatus} = useDarkMode();

    useEffect(() => {
        jwtInstance.get(API_EXCHANGE.get)
            .then(response => {
                if (response.status === 200) {
                    setExchangeRate(response.data);
                }
            }).catch(error => {
            errorResponse(error, 'Error', 'danger');
        })
    }, []);

    return (
        <>
            <span
                className={`h6 mb-0 fw-bold ${darkModeStatus ? 'text-light' : 'text-muted'}`}>{new Date(exchangeRate?.date).toLocaleDateString('ro-RO')}</span>
            <SubheaderSeparator/>
            <div className={'fw-bold d-flex align-items-center'}>
                <div style={{marginRight: 4}} className='d-flex'>
                    <div className='flex-shrink-0'>
                        <img
                            srcSet={'/img/eurRate.png'}
                            alt={'company'}
                            width='25'
                            height='25'
                        />
                    </div>
                </div>
                <span className={darkModeStatus ? 'text-light' : 'text-dark'}>
                {`${exchangeRate?.eurRate} RON`}
            </span>
            </div>
            <div className={'fw-bold d-flex align-items-center'}>
                <div style={{marginRight: 4}} className='d-flex'>
                    <div className='flex-shrink-0'>
                        <img
                            srcSet={'/img/usdRate.png'}
                            alt={'company'}
                            width='25'
                            height='25'
                        />
                    </div>
                </div>
                <span className={darkModeStatus ? 'text-light' : 'text-dark'}>
                {`${exchangeRate?.usdRate} RON`}
            </span>
            </div>
        </>
    )
}

export default ExchangeRate;

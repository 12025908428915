import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface UtilityState {
    isAdded: boolean,
    isUpdated: boolean
}

const initialState: UtilityState = {
    isAdded: false,
    isUpdated: false,
}

export const UtilitySlice = createSlice({
    name: 'utility',
    initialState,
    reducers: {
        addUtility: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateUtility: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addUtility, updateUtility} = UtilitySlice.actions;
export default UtilitySlice.reducer;

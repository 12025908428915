import {createSlice} from "@reduxjs/toolkit";

interface CompanyState {
    isUpdated: boolean,
    isAdded: boolean,
}

const initialState: CompanyState = {
    isUpdated: false,
    isAdded: false,
}

export const CompanySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        updateCompany: (state, action) => {
            state.isUpdated = action.payload
        },

        addCompany: (state, action) => {
            state.isAdded = action.payload
        }
    }
});

export const {updateCompany, addCompany} = CompanySlice.actions;
export default CompanySlice.reducer;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InventoryState {
    isAdded: boolean,
    isUpdated: boolean,
    isItemAdded: boolean,
    isItemUpdated: boolean,
}

const initialState: InventoryState = {
    isAdded: false,
    isUpdated: false,
    isItemAdded: false,
    isItemUpdated: false,
}

export const InventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        addInventory: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateInventory: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        },
        addInventoryItem: (state, action) => {
            state.isItemAdded = action.payload;
        },
        updateInventoryItem: (state, action) => {
            state.isItemUpdated = action.payload;
        }
    }
});

export const {addInventory, updateInventory, addInventoryItem, updateInventoryItem} = InventorySlice.actions;
export default InventorySlice.reducer;

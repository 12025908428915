import {createContext, FC, ReactNode, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import jwtInstance from "../guards/jwt.interceptor";
import {API_ACCESS_RIGHT} from "../utils/restApi";
import {errorResponse} from "../utils/getResponse";
import AuthContext from "./authContext";

export interface IAccessRightContextProps {
    accessRight?: any,
}

const AccessRightContext = createContext<IAccessRightContextProps>({} as IAccessRightContextProps);

interface IAccessRightContextProviderProps {
    children: ReactNode;
}

export const AccessRightContextProvider: FC<IAccessRightContextProviderProps> = ({children}) => {
    const [accessRight, setAccessRight] = useState<any>(null);
    const {isLoading} = useContext(AuthContext);
    useEffect(() => {
            jwtInstance.get(API_ACCESS_RIGHT.byUuid)
                .then(response => {
                    if (response.status === 200) {
                        setAccessRight(response.data);
                    }
                }).catch(error => {
                errorResponse(error, 'Error', 'danger');
            });
    }, [isLoading]);

    return <AccessRightContext.Provider value={{accessRight}}>{children}</AccessRightContext.Provider>
};

AccessRightContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AccessRightContext;

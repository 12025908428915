import React from 'react';
import Header, {HeaderLeft, HeaderRight} from '../../../layout/Header/Header';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import useDarkMode from "../../../hooks/useDarkMode";
import ExchangeRate from "../../../components/dashboard/exchange-rate/ExchangeRate";

const DefaultHeader = () => {
    const {width} = useDeviceScreen();
    const {darkModeStatus} = useDarkMode();
    const user = JSON.parse(`${localStorage.getItem('user')}`);
    return (
        <>
            {user?.token && (
                <Header>
                    <HeaderLeft>
                        <div style={{fontSize: '16px', fontWeight: 500}}
                             className={darkModeStatus ? 'text-light' : 'text-dark'}>
                            {`Hi, ${user?.lastName}`}
                        </div>
                        <div className={`small ${darkModeStatus ? 'text-light' : 'text-muted'}`}>
                            {user?.email}
                        </div>
                    </HeaderLeft>
                    <HeaderRight className={'d-flex align-items-center'}>
                        <ExchangeRate/>
                    </HeaderRight>
                </Header>
            )}
        </>
    );
};

export default DefaultHeader;

import {useEffect, useState} from "react";

const BackToTopButton = () => {
    const [showButton, setShowButton] = useState<boolean>(false);

    useEffect(() => {
        const checkScrollHeight = () => {
            if (!showButton && window.pageYOffset > 300) {
                setShowButton(true);
            } else if (showButton && window.pageYOffset <= 300) {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', checkScrollHeight);
        return () => {
            window.removeEventListener('scroll', checkScrollHeight);
        };
    }, [showButton]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <span style={{
            position: 'fixed',
            bottom: 55,
            right: 22,
            alignItems: 'center',
            height: 28,
            width: 28,
            justifyContent: 'center',
            zIndex: 1000,
            cursor: 'pointer',
            animation: 'fadeIn 0.3s',
            opacity: 0.7,
            background: '#0C359E',
            borderRadius: 4,
            transition: 'opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s',
            display: showButton ? 'flex' : "none"

        }} onClick={scrollToTop}>
            {/*<svg*/}
            {/*    width={24}*/}
            {/*    height={24}*/}
            {/*    viewBox="0 0 24 24"*/}
            {/*    fill="none"*/}
            {/*    stroke="#ffffff"*/}
            {/*    strokeWidth={2}*/}
            {/*    strokeLinecap="round"*/}
            {/*    strokeLinejoin="round"*/}
            {/*>*/}
            {/*    <path d="M12 19V5M5 12l7-7 7 7" />*/}
            {/*</svg>*/}
            <i className="fa-duotone fa-arrow-up-from-bracket text-light"></i>
        </span>
    );
};

export default BackToTopButton;

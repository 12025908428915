import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ContractState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: ContractState = {
    isAdded: false,
    isUpdated: false
}

export const ContractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        addContract: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateContract: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addContract, updateContract} = ContractSlice.actions;
export default ContractSlice.reducer;

import {FC, useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import AuthContext from "../contexts/authContext";
import {demoPages} from "../menu";


const AuthGuard: FC<any> = ({children}) => {
    // @ts-ignore
    const {user} = useContext(AuthContext);
    const navigation = useNavigate();
    const location = useLocation();

    useEffect(() => {

        authCheck(location.pathname);
    }, [user, location]);


    const authCheck = (url: string) => {
        const publicPaths = ['auth-pages'];
        const path = url.split('/')[1];
        const sessionUser = JSON.parse(`${localStorage.getItem('user')}`);

        if (!sessionUser && !publicPaths.includes(path)) {
            navigation(`${demoPages.login.path}`);
        }

        if (sessionUser && publicPaths.includes(path)) {
            navigation('/');
        }

    };


    return children;
}

export default AuthGuard;

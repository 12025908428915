import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface RentalRequestState {
    isUpdated: boolean,
    isAdded: boolean,
    isDeleted: boolean,
}

const initialState: RentalRequestState = {
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
}

export const RentalRequestSlice = createSlice({
    name: 'invitation',
    initialState,
    reducers: {
        addRentalRequest: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateRentalRequest: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        },
        deleteRentalRequest: (state, action) => {
            state.isDeleted = action.payload;
        }
    }
});

export const {updateRentalRequest, deleteRentalRequest, addRentalRequest} = RentalRequestSlice.actions;
export default RentalRequestSlice.reducer;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface TicketServiceItemState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: TicketServiceItemState = {
    isAdded: false,
    isUpdated: false,
}

export const TicketServiceItemSlice = createSlice({
    name: 'ticketServiceItem',
    initialState,
    reducers: {
        addTicketServiceItem: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateTicketServiceItem: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addTicketServiceItem, updateTicketServiceItem} = TicketServiceItemSlice.actions;
export default TicketServiceItemSlice.reducer;
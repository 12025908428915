
export const getNameOfRole = (role?: string) => {
    if (role === 'ROLE_SUPER_ADMIN') {
        return 'Super Admin';
    } else if (role === 'ROLE_ADMIN') {
        return 'Admin';
    } else {
        return 'User';
    }
};

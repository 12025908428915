import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ApartmentState {
    isAdded: boolean,
    isUpdated: boolean
}

const initialState: ApartmentState = {
    isAdded: false,
    isUpdated: false,
}

export const ApartmentSlice = createSlice({
    name: 'apartment',
    initialState,
    reducers: {
        addApartment: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateApartment: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addApartment, updateApartment} = ApartmentSlice.actions;
export default ApartmentSlice.reducer;

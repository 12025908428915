import React from 'react';
import Header, {HeaderLeft} from '../../../layout/Header/Header';
import CommonHeaderRight from "./CommonHeaderRight";

const DashboardHeader = () => {

    return (
        <Header>
            <HeaderLeft>
                {' '}
            </HeaderLeft>
            <CommonHeaderRight/>
        </Header>
    );
};

export default DashboardHeader;

import { createSlice } from "@reduxjs/toolkit";

interface BuildingState {
  isUpdated: boolean,
  isAdded: boolean,
}

const initialState: BuildingState = {
  isUpdated: false,
  isAdded: false
};

export const BuildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    updateBuilding: (state, action) => {
      state.isUpdated = action.payload;
    },
    addBuilding: (state, action) => {
      state.isAdded = action.payload;
    }
  }
});

export const { updateBuilding, addBuilding } = BuildingSlice.actions;
export default BuildingSlice.reducer;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InvoiceState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: InvoiceState = {
    isAdded: false,
    isUpdated: false,
}

export const InvoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        addInvoice: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateInvoice: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addInvoice, updateInvoice} = InvoiceSlice.actions;
export default InvoiceSlice.reducer;

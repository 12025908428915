import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface TicketState {
    isAdded: boolean,
    isUpdated: boolean,
}

const initialState: TicketState = {
    isAdded: false,
    isUpdated: false
}

export const TicketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        addTicket: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateTicket: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const {addTicket, updateTicket} = TicketSlice.actions;
export default TicketSlice.reducer;
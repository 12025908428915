import {StylesConfig} from "react-select";


const selectStyles: StylesConfig<any> = {
    container: (base) => ({
        ...base,

    }), valueContainer: (base) => ({
        ...base,
        padding: 5,
        color: '#323232',
        fontWeight: 600,
        borderRadius: '1rem',

    }), control: (base, state) => ({
        ...base,
        border: state.isFocused ? 'none' : '1px solid #f8f9fa',
        borderRadius: '0.5rem',
        boxShadow: state.isFocused ? ' 0px 0px 2px 4px rgba(160,143,227,0.55)' : 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',
        backgroundColor: '#f8f9fa',
    }), placeholder: (base) => ({
        ...base,
        fontWeight: 500,
    }), option: (base, {isFocused}) => ({
        ...base,
        color: 'black',
        fontWeight: 500,
        cursor: 'pointer',
    })
}

export default selectStyles;

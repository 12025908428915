import React, {createContext, FC, ReactNode, useState} from 'react';
import PropTypes from 'prop-types';
import jwtInstance from "../guards/jwt.interceptor";
import {API_AUTH} from "../utils/restApi";
import {dashboardMenu, demoPages} from "../menu";
import {useNavigate} from "react-router-dom";
import {errorResponse} from "../utils/getResponse";

export interface IAuthContextProps {
	isLoading?: boolean,
	user?: any;
	login?: any;
	logout?: any;
	isUnprotect?:boolean;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({children}) => {

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [isUnprotect, setIsUnprotect] = useState(true);
	// @ts-ignore
	const [user, setUser] = useState( null);


	const login = async (data: any) => {
		setIsLoading(true);
		await jwtInstance.post(API_AUTH.login, data)
			.then(response => {
				if (response.status === 204) {
					setIsLoading(false);
					navigate(`${demoPages.verifyAccount.path}`);
				}

				if (response.data.token) {
					setIsLoading(false);
					setUser(response.data);
					localStorage.setItem('user', JSON.stringify(response.data));
					navigate(`${dashboardMenu.dashboard.path}`);
				}
			}).catch(error => {
				setIsLoading(false);
				errorResponse(error, 'Error', 'danger');
			})
	};

	const logout = () => {
		setUser(null);
		localStorage.clear();
		navigate(`/${demoPages.login.path}`);
	};


	return <AuthContext.Provider value={{
		isLoading,
		login,
		logout,
		user,
		isUnprotect
	}}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;

import showNotification from "../components/extras/showNotification";
import Icon from "../components/icon/Icon";
import React from "react";


export const errorResponse = (error: any, title: string, type: string) => {
		if(error?.code == "ERR_CANCELED"){
			return;
		}
    if (!error.response) {
        showNotification(<span className='d-flex align-items-center'>
												<Icon icon='Info' size='lg' className='me-1' color={"light"}/>
												<span>{'Server connection'}</span>
											</span>,
            `Server connection was refused!`, type);
    } else {
        showNotification(<span className='d-flex align-items-center'>
												<Icon icon='Info' size='lg' className='me-1' color={"light"}/>
												<span>{title}</span>
											</span>,
            `${error.response.data.message}`, type);
    }
};

export const errorResponseDownloadData = (message: string, title: string, type: string) => {
	showNotification(<span className='d-flex align-items-center'>
												<Icon icon='Info' size='lg' className='me-1' color={"light"}/>
												<span>{title}</span>
											</span>,
		`${message}`, type);
};

export const successResponse = (response: any, title: string, type: string) => {
	showNotification(<span className='d-flex align-items-center'>
												<Icon icon='Info' size='lg' className='me-1' color={"light"}/>
												<span>{title}</span>
											</span>,
		`${response.data.message}`, type);
}

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ReceiptState {
    isAdded: boolean,
    isUpdated: boolean
}

const initialState: ReceiptState = {
    isAdded: false,
    isUpdated: false,
}

export const ReceiptSlice = createSlice({
    name: 'receipt',
    initialState,
    reducers: {
        addReceipt: (state, action: PayloadAction<any>) => {
            state.isAdded = action.payload;
        },
        updateReceipt: (state, action: PayloadAction<any>) => {
            state.isUpdated = action.payload;
        }
    }
});

export const  {addReceipt, updateReceipt} = ReceiptSlice.actions;
export default ReceiptSlice.reducer;
